<template>
  <ViewPortInfo></ViewPortInfo>
  <LayoutBase></LayoutBase>
</template>
<script>
import ViewPortInfo from "@/components/ViewPortInfo";
import LayoutBase from "@/layouts/LayoutBase";


export default {
  name : "App",
  components : {LayoutBase, ViewPortInfo},
  data() {
    return {
      message : "App Hello Vue",
    };
  },
}
</script>
<style lang="scss">
</style>
