<template>
  <div id="CustomAlert" class="CustomAlert" :class="{ on : $_Store.layer_popup_data.CustomAlert.visible }">
    <div class="bg" @click="closeAlert"></div>
    <div class="message_box">
      <!--<div class="head"></div>-->
      <div class="body"
           v-html="$_Store.layer_popup_data.CustomAlert.text">
      </div>
      <div class="foot">
        <button type="button" @click="closeAlert">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name : "CustomAlert",
  data() {
    return {
      message : "Hello",
    };
  },
  methods : {
    closeAlert() {
      this.$_Store.layer_popup_data.CustomAlert.visible = false;
      if (this.$_Store.layer_popup_data.CustomAlert.callback) {
        this.$_Store.layer_popup_data.CustomAlert.callback();
      }
      
    },
  },
}
</script>
