import '@/assets/scss/global.scss';
import '@/assets/js/global';
import {createApp} from 'vue';
import App from './App.vue';
import router from '@/routes/index';
import {createPinia} from 'pinia';
import {useCommonStore} from "@/stores/common";
import {VueReCaptcha} from 'vue-recaptcha-v3';//g_recaptcha_v3

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.config.globalProperties.$_Store = useCommonStore(); // 전역 설정
app.use(VueReCaptcha, {siteKey : '6LeQ9KwgAAAAADOrv4Onq5SNjPDboZYaiwnBHcTF'});//g_recaptcha_v3 - 도메인 마다 달라지는 부분 - https://enter.devlabs.co.kr/
app.mount('#app');
app._container.dataset.env = process.env.NODE_ENV;

// console.log('process.env.VUE_APP_OUTPUT_PATH : ' + process.env.VUE_APP_OUTPUT_PATH);

let is_mobile = window.DEVICE_CHECK();

if ( (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage") && is_mobile){
  window.location.href = window.location.href.replace('www.', 'm.');
}

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage"){
  
  const urlStr = window.location.href;
  const url = new URL(urlStr);
  const urlParams  = url.searchParams;
  const cookie_key = 'n';
  let checkEvent = urlParams.get(cookie_key);
  app.config.globalProperties.$_Store.codename = checkEvent;
  // console.log('checkEvent : ' + checkEvent);
  url_parameter_del();
  
/*
  if(checkEvent != null && checkEvent != 'null') {
    //쿠키값에 저장되었을 경우
    
  } else {
    //쿠키값이 없을 경우
    setCookie(cookie_key,checkEvent,"30") //변수, 변수값, 저장기간(1은 Day 하루 동안을 의미합니다.)
  }
*/
  setCookie(cookie_key,checkEvent,"30") //변수, 변수값, 저장기간(1은 Day 하루 동안을 의미합니다.)
}

// 쿠키 생성 함수
function setCookie(cName, cValue, cDay){
  var expire = new Date();
  expire.setDate(expire.getDate() + Number(cDay));
  var cookies = cName + '=' + escape(cValue) + '; path=/ '; // 한글 깨짐을 막기위해 escape(cValue)를 합니다.
  if(typeof cDay != 'undefined') cookies += ';expires=' + expire.toUTCString() + ';';
  document.cookie = cookies;
}

// url 파라메타 삭제
function url_parameter_del(){
  history.replaceState({}, null, location.pathname);
}
