<template>
  <div id="FullLayer_0" class="full_layer_popup" :class="{ on : $_Store.layer_popup_data.FullLayer_0.visible }">
    <div class="bg"></div>
    <div class="popup" @click="closePopup">
      <div class="head">
        <button type="button" class="close_btn" @click="closePopup"></button>
      </div>
      <div class="body">
        <div class="creator_body" @click="creatorClick">
          <SwiperCreator @swiper_init="swiperInit"></SwiperCreator>
          <div class="info_section">
            <div class="area0">
              <div class="text0">{{ getName }}</div>
              <!--<div>모델</div>-->
            </div>
            <div class="area1" v-html="getDesc"></div>
          </div>
        </div>
      </div>
      <!--<div class="foot">foot</div>-->
    </div>
  </div>
</template>
<script>
import SwiperCreator from "@/components/swiper/SwiperCreator";
import Util from "@/utils/Util";

export default {
  name : "FullLayer_0",
  components : {SwiperCreator},
  data() {
    return {
      message : "Hello Vue",
      swiper : null,
    };
  },
  updated() {
    this.$nextTick(() => {
      // 전체 화면내용이 다시 렌더링된 후에 아래의 코드가 실행됩니다.
      // console.log("FullLayer_0 updated");
      this.swiper.slideTo(0, 0);
      // body 스크롤 비활성화
      const body = document.body;
      if (this.$_Store.layer_popup_data.FullLayer_0.visible) {
        Util.addClass(body, 'deactive_scroll');
      } else {
        Util.removeClass(body, 'deactive_scroll');
      }
    })
  },
  computed : {
    getName() {
      return this.$_Store.layer_popup_data.FullLayer_0.bj_data.bjname;
    },
    getDesc() {
      return this.$_Store.layer_popup_data.FullLayer_0.bj_data.contents;
    },
  },
  methods : {
    swiperInit(swiper) {
      this.swiper = swiper;
    },
    closePopup() {
      // console.log("closePopup");
      this.$_Store.layer_popup_data.FullLayer_0.visible = false;
    },
    creatorClick(event){
      event.stopPropagation();
    }
  },
}
</script>