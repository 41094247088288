export default {
  CustomAlert : {
    visible : false,
    text : "",
    callback : null,
  },
  LayerPopup_0 : {
    visible : false,
  },
  FullLayer_0 : {
    visible : false,
    bj_data : {
      idx : "",
      bjname : "",
      contents : "",
      image : []
    }
  },
  FullLayer_1 : {
    visible : false,
  },
}