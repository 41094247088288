<template>
  <router-link to="/career" class="goto_career_btn" :class="{ hide: $_Store.is_career_btn_hide }">
    <div>지원하기</div>
  </router-link>
</template>
<script>
export default {
  name : "GoToCareer",
  data() {
    return {
      timer : null,
    };
  },
  methods : {
    onScroll() {
      // console.log('onScroll');
      this.$_Store.is_career_btn_hide = true;
  
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.$route.name !== "채용") {
          this.$_Store.is_career_btn_hide = false;
        }
      }, 800);
    },
  },
  created() {
    // 인스턴스가 생성된 후 동기적으로 호출
    // console.log("GoToCareer created");
    
    
    window.addEventListener('scroll', this.onScroll);
  },
  unmounted() {
    // 컴포넌트 인스턴스가 마운트 해제(unmounted)된 후 호출됩니다. 이 훅이 호출되면,
    // 컴포넌트 인스턴스의 모든 디렉티브가 바인딩 해제되고,
    // 모든 이벤트 리스너가 제거되며, 모든 자식 컴포넌트 컴포넌트도 마운트 해제(unmounted)됩니다.
    window.removeEventListener('scroll', this.onScroll);
  },
}
</script>
<style lang="scss" scoped>
.goto_career_btn{
  position:fixed; z-index:100; bottom:40px; right:22px;
  transition:all 300ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
  opacity:1;
  
  > div{
    display:flex; justify-content:center; align-items:center;
    width:100px; height:100px; border-radius:50px;
    background-color:$COLOR_0; color:$N_COLOR_0;
    font-weight:800; font-size:18px; line-height:21.5px; text-align:center; letter-spacing:-0.5px;
    
  }
}

.goto_career_btn.hide{
  opacity:0;
}
</style>