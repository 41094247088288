<template>
  <div id="layer_pop_container">
    <CustomAlert></CustomAlert>
    <LayerPopup_0></LayerPopup_0>
    <FullLayer_0></FullLayer_0>
    <FullLayer_1></FullLayer_1>
  </div>
</template>
<script>
import LayerPopup_0 from "@/components/layer_popup/LayerPopup_0";
import FullLayer_0 from "@/components/full_layer_popup/FullLayer_0";
import CustomAlert from "@/components/CustomAlert";
import FullLayer_1 from "@/components/full_layer_popup/FullLayer_1";
export default {
  name : "LayerPopupContainer",
  components : {
    FullLayer_1,
    CustomAlert,
    LayerPopup_0,
    FullLayer_0,
  },
  data() {
    return {
      message : "Hello Vue",
    };
  },
}
</script>
