<template>
  <div id="page" class="LayoutEmpty">
    <main>
      <slot name="default"></slot>
    </main>
  </div>
</template>
<script>
export default {
  name : "LayoutEmpty",
};
</script>
