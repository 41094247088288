export default [
  {
    path : "/_guide/main",
    name : "가이드 메인",
    component : () => import("@/pages/_guide/PageGuide.vue"),
  },
  {
    path : "/_guide/lp",
    name : "레이어 팝업 샘플",
    component : () => import("@/pages/_guide/PageLayerPop.vue"),
  },
  {
    path : "/_guide/swiper",
    name : "swiperjs 샘플",
    component : () => import("@/pages/_guide/PageSwiper.vue"),
  },
];