import {defineStore} from "pinia";
import {fetchCreator, fetchNews, fetchTodoList, fetchUserList} from '@/apis/index';
import LayerPopupData from '@/stores/LayerPopupData.js';

export const useCommonStore = defineStore("common", {
  
  // state : 여러 컴포넌트에 공유되는 데이터 ( data )
  state : () => ({
    codename : "",
    page_name : "undefined",
    hide_header : false,
    fill_header : false,
    active_aside : false,
    is_career_btn_hide : true,
    num : 123,
    user_list : [1, 2, 3],
    todo_list : [],
    layer_popup_data : LayerPopupData,
    news_list : [],
    news_list_for_main : [],
    creator_list : [],
    creator_list_for_main : [],
  }),
  
  // getters : 연산된 state 값을 접근하는 속성 ( computed )
  getters : {
    getDoubleCount(state) {
      return state.num * 2;
    }
  },
  
  // actions : state 값을 변경하는 이벤트 로직, 메서드 ( methods )
  actions : {
  
    async takeCreator() {
      try {
        const response = await fetchCreator();
        // console.log(response);
        // this.creator_list = response.data;
        const data = response.data;
        // console.log(data);
        // const result = data.result;
        // const code = data.code;
        const datas = data.datas;
        // console.log("datas : ", datas);
        this.creator_list = datas;
      
        this.creator_list_for_main = [];
        datas.map((obj) => {
          this.creator_list_for_main.push(obj.image[0]);
        });
      
      } catch (error) {
        console.log(error);
      } finally {
        // 이 블록에는 try 블록에서 일어난 일에 관계없이 무조건 실행될 코드가 위치한다.
      }
    },
  
    takeCreator2(){
      const result = fetchCreator()
        .then((response) => {
          // success
          // console.log(response);
          // this.creator_list = response.data;
          const data = response.data;
          // console.log(data);
          // const result = data.result;
          // const code = data.code;
          const datas = data.datas;
          // console.log("datas : ", datas);
          this.creator_list = datas;
        
          this.creator_list_for_main = [];
          datas.map((obj) => {
            this.creator_list_for_main.push(obj.image[0]);
          });
        })
        .catch((error) => {
          // error
          console.log(error);
        })
        .then(() => {
          // complete
        });
      return result;
    },
    
    takeNews() {
      fetchNews()
        .then((response) => {
          // success
          // console.log(response);
          const data = response.data;
          const datas = data.datas;
          // console.log(datas);
          this.news_list = datas;
          this.news_list_for_main = datas.slice(0, 6);
          
        })
        .catch((error) => {
          // error
          console.log(error);
        })
        .then(() => {
          // complete
        });
    },
    
    async takeUserList() {
      
      // 동기 호출 예제
      try {
        const response = await fetchUserList();
        console.log(response);
        this.user_list = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        // 이 블록에는 try 블록에서 일어난 일에 관계없이 무조건 실행될 코드가 위치한다.
      }
    },
    
    takeTodoList() {
      
      // 비동기 호출 예제
      fetchTodoList()
        .then((response) => {
          // success
          // console.log(response);
          this.todo_list = response.data;
        })
        .catch((error) => {
          // error
          console.log(error);
        })
        .then(() => {
          // complete
        });
      
    },
  },
  
});