export default [
  {
    path : "/login",
    name : "/login",
    component : () => import("@/pages/login/PageLogin.vue"),
    
  },
  {
    path : "/join",
    name : "/join",
    component : () => import("@/pages/login/PageJoin.vue"),
  },
];