<template>
  <footer>© 2022. UX+ ENTERTAINMENT. All rights reserved.</footer>
</template>
<script>
export default {
  name : "FooterBasic",
  data() {
    return {
      message : "Hello Vue",
    };
  },
}
</script>
