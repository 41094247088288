<template>
  <router-link class="viewport_info" to="/_guide/main"
               v-if="active"
               @click.stop>
    <span>{{ getNodeEnv }}</span> /
    CW : <span class="wid">{{ client_width }}</span>
    <span> x </span>
    <span class="hei">{{ client_height }}</span>
    / OW : <span class="wid2">{{ offset_width }}</span>
    <span> x </span>
    <span class="hei2">{{ offset_height }}</span>
  </router-link>
</template>
<script>
export default {
  name : "ViewPortInfo",
  data() {
    return {
      message : "Hello Vue",
      active : true,
      client_width : 0,
      client_height : 0,
      offset_width : 0,
      offset_height : 0,
    };
  },
  computed : {
    getNodeEnv() {
      return process.env.NODE_ENV;
    }
  },
  created() {
    // 인스턴스가 생성된 후 동기적으로 호출
    if (process.env.NODE_ENV === "production") this.active = false;
    else this.active = true;
  },
  mounted() {
    const vm = this;
    this.$nextTick(() => {
      // 전체 화면내용이 렌더링된 후에 아래의 코드가 실행됩니다.
      // Listen for resize changes
      window.addEventListener("resize", function () {
        vm.setSize();
      });
      vm.setSize();
    })
  },
  methods : {
    setSize() {
      this.client_width = window.innerWidth;
      this.client_height = window.innerHeight;
      this.offset_width = window.outerWidth;
      this.offset_height = window.outerHeight;
    },
  },
}
</script>
<style lang="scss" scoped>
/**/
.viewport_info{
  position:fixed; z-index:999999; top:0px; left:50%; transform:translateX(-50%);
  background:rgba(0, 0, 0, 0.7);
  color:#00ffff; font-size:11px; white-space:nowrap;
  cursor:pointer; padding:3px;
  -webkit-touch-callout:none; -webkit-user-select:none; -khtml-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none;
}

.viewport_info:before{ margin-right:5px; display:inline-block; }

/*.viewport_info:before{ content:'CSS : DESKTOP /'; }*/
/* [ @~lt ] 1439이하 - 랩탑 and 테블릿 가로 이하▼ 화면*/
@media screen and (max-width:1439px){
  /*.viewport_info:before{ content:'CSS : LAPTOP /'; }*/
}
</style>