<template>
  <!--<component :is="this.$route.meta.layout || 'LayoutDefault'">-->
  <component :is="this.$route.meta.layout">
    <router-view></router-view>
  </component>
</template>
<script>
import LayoutDefault from "@/layouts/LayoutDefault";
import LayoutEmpty from "@/layouts/LayoutEmpty";

export default {
  name : "LayoutBase",
  components : {LayoutDefault, LayoutEmpty},
  data() {
    return {
      message : "HI",
      layout : "",
    };
  },
};
</script>
